<template>
	<div class="page">
		<top />
		<div style="width: 100%;height: 55px;"></div>
		<designer />
			<div class="home">
				<div  @click="go('My')">我的主页</div>
				<div><</div>
				<div style="color: #E66617;">提现明细</div>
			</div>
			<div class="off">
				<div class="offline">
					<div class="timechoose">
						<div  @click="openPicker(0)">{{ startTime }}</div>
						<div  @click="openPicker(1)">{{ endTime }}</div>
						<div @click="getData">搜索</div>
					</div>
					<mt-datetime-picker
					  v-model="pickerVisible"
					  type="date"
					  ref="picker"
					  year-format="{value} 年"
					  month-format="{value} 月"
					  date-format="{value} 日"
					  @confirm="handleConfirm">
					</mt-datetime-picker>
					<div class="state withdrawal">
						<div>提现金额</div>
						<div>提交时间</div>
						<div>提现状态</div>
					</div>
					<div v-for="(item,index) in tableData" :key="index">
						<div class="state details">
							<div>{{ item.money }}</div>
							<div>{{ item.create_time }}</div>
							<div style="color: #E66617;" v-if="item.status == 1">提现中</div>
							<div style="color: #08243C;" v-if="item.status == 2">提现成功</div>
							<div style="color: #E66617;" v-if="item.status == 3">提现驳回</div>
						</div>
					</div>
					
				</div>
			</div>

			<info />
			<div style="width: 100%;height: 60px;background-color: #F6F6F6;"></div>
			<foot />
		</div>
</template>

<script>
	import top from "../../components/common/header.vue";
	import foot from "../../components/common/footer.vue";
	import info from "../../components/common/qrCode.vue";
	import designer from "../../components/common/designer.vue";
	import { withdrawalList } from "../../request/api";
	export default {
		components: {
			top,
			foot,
			designer,
			info
		},
		data() {
			return {
				pickerVisible:'',
				value:'',
				startTime:'开始时间',
				endTime:'结束时间',
				timeIndex:'',
				tableData: [],
			};
		},
		created() {
			this.getData();
		},
		methods: {
			getData() {
			  withdrawalList({
			    start_time: this.startTime == '开始时间'?'':this.startTime,
			    end_time: this.endTime == '结束时间'?'':this.endTime,
			    page: "",
			    size: "",
			  }).then((res) => {
			    console.log(res);
			    this.tableData = res.data.list;
			  });
			},
			openPicker(ind) {
				// this.pickerVisible = new Date()
				this.timeIndex = ind
				this.$refs.picker.open();
				
			},
			handleConfirm(date) {
				var month = Number(date.getMonth())+1
				if (this.timeIndex == 0) {
					this.startTime = date.getFullYear() +'-'+ month + '-' + date.getDate();
				} else {
					this.endTime = date.getFullYear() +'-'+ month + '-' + date.getDate();
				}
				
			},
			go(url) {
				this.$router.push({
					name: url,
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.page {
		background: #F6F6F6;

		.home {
			padding: 0 20px;
			display: flex;
			align-items: center;
			font-size: 13px;

			div {
				margin-right: 7px;
			}
		}
		.off {
			margin-top: 15px;
			padding: 0 10px;
			.offline {
				padding: 12px;
				font-size: 12px;
				background-color: #FFFFFF;
				color: #08243C;
				.timechoose {
					display: flex;
					justify-content: space-between;
					align-items: center;
					div {
						width: 115px;
						height: 30px;
						text-align: center;
						line-height: 30px;
						border: 1px solid #CDD6DE;
						color: #999999;
						border-radius: 3px;
					}
					div:last-child {
						width: 60px;
						height: 30px;
						text-align: center;
						line-height: 30px;
						background-color: #E66617;
						color: #FFFFFF;
						border: none;
						border-radius: 3px;
					}
				}
				.state {
					display: flex;
					justify-content: space-between;					align-items: center;
				}
				.withdrawal {
					border-bottom: #F5F6F7 1px solid;
					font-size: 13px;
					padding: 20px 0;
				}
				.details {
					padding: 20px 8px;
					border-bottom: #F5F6F7 1px solid;
					font-size: 12px
				}
			}
		}
		
	}
</style>
